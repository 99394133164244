<template>
    <div class="exception-body error-page">
        <div class="exception-top">
            <img src="layout/images/icon-error.png" alt="poseidon-layout" />
        </div>

        <div class="exception-bottom">
            <div class="exception-wrapper">
                <span class="exception-summary">Exception Occured</span>
                <span class="exception-detail">Please Contact System Admin</span>
                <router-link to="/">
                    <Button label="Dashboard" icon="pi pi-home"></Button>
                </router-link>
                <router-link to="/" class="logo">
                    <img src="layout/images/logo.svg" alt="poseidon-layout" class="logo-icon"  />
                </router-link>
                <span class="exception-footer">PrimeVue Poseidon</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>